<template>
    <v-data-table
      dense
      :headers="headers"
      :items="data"
      item-key="id"
      class="elevation-1"
    ></v-data-table>
  </template>
  
  <script>
  export default {
    name: 'ViewLeaderboard',
    data() {
      return {
        headers: [
          { text: 'Rank', value: 'rank' },
          { text: 'ID', value: 'id' },
          { text: 'Email', value: 'email' },
          { text: 'Name', value: 'fullName' },
          { text: 'Username', value: 'username' },
          { text: 'Phone', value: 'phone' },
          { text: 'Score', value: 'score' },
        ],
        players: [],
        data: [],
      };
    },
    created() {
      this.fetchLeaderboardData();
    },
    methods: {  
  fetchLeaderboardData() {
    this.$http
      .get('/api/ace-cup/top-five-users-information')
      .then(response => {
  const lines = response.data.split('\n');
  const data = lines
    .filter(line => line.trim() !== '') // Skip empty lines
    .map(line => {
      const values = line.split('\t');
            const rank = parseInt(values[0]);
            const id = parseInt(values[1]);
            const email = values[2];
            const fullName = values[3]  + " " + values[4];
            const username = values[5];
            const phone = values[6];
            const score = parseInt(values[7]);
            return {
              rank,
              id,
              email,
              fullName,
              username,
              phone,
              score
            };
          });
        this.data = data;
      })
      .catch(error => {
        console.error('Error fetching leaderboard data:', error);
      });
  }
    },
  };
  </script>
  
  <style scoped>
  </style>
  